/* eslint-disable import/no-import-module-exports */
import { createRoot, hydrateRoot } from 'react-dom/client';
import { document, window } from '../../../js/globals';
import AuthenticationFlowApp from './Components/App/AuthenticationFlowApp';

if (module.hot) {
    module.hot.accept();
}

if (document && window) {
    const ssrElement = document.getElementById('mysurprise-auth-flow-ssr');

    const { MYSURPRISE_AUTH_FLOW_STATE } = window;

    if (ssrElement) {
        hydrateRoot(ssrElement, <AuthenticationFlowApp preloadedState={MYSURPRISE_AUTH_FLOW_STATE} />);
    } else {
        const root = createRoot(document.getElementById('mysurprise-auth-flow') as Element);
        root.render(<AuthenticationFlowApp preloadedState={MYSURPRISE_AUTH_FLOW_STATE} />);
    }
}
